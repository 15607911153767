import { get, put,Delete,post } from '../axios'

// 平台端-门店列表
export const storeDeptList = params => get(`/user/web/store/deptList`,params);
// 平台端-部门列表
export const deptTree = params => get(`/user/web/store/deptTree`,params);
// // 平台端-门店管理-新增
export const deptAdd = params => post(`/user/web/store/deptAdd`,params);

// // 门店端-部门修改
export const deptUpdate = params => put(`/user/web/store/deptUpdate`,params);
// // // 应用管理-删除
export const deptDelete = params => Delete('/user/web/store/deptDelete/' + params, '');

// //平台端-门店管理-重置密码
// export const storeUpdatePassword = params => put(`/web/store/updatePassword/${params}`);

// // 平台端-门店管理-详情
// export const storedetail = params => get('/web/store/detail/' + params, '');

// // 平台端-门店管理-是否启用-修改
// export const stateUpdate = params => put(`/web/store/state/update`,params);

// // 平台端-设置店铺积分
// export const updateIntegral = params => put(`web/store/updateIntegral`,params);

// 平台端-查看店铺积分
export const storeIntegral = params => get(`/user/web/store/integral/${params}`);