<template>
  <div class="divBox">
    <el-card class="box-card">
      <div class="mb20 acea-row">
        <el-button size="small" type="primary" class="mb10" @click="onAdd">添加部门</el-button>
        <el-button size="small" type="primary" class="mb10" @click="importFun">导入</el-button>
      </div>
      <el-table   v-loading="listLoading" :data="tableData.data"  size="small" row-key="id" :default-expand-all="false">
        <!-- <el-table-column label="id" prop="id" min-width="80">
        </el-table-column> -->
        <el-table-column label="序号" type="index" width="150">
        </el-table-column>
        <el-table-column label="分类名称" prop="name" min-width="80">
        </el-table-column>
        <el-table-column label="人数" prop="userNum" min-width="80">
        </el-table-column>
        <!-- <el-table-column label="分类图标" min-width="80">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.icon ? scope.row.icon : moren"
                :preview-src-list="[scope.row.icon ? scope.row.icon : moren]"
              />
            </div>
          </template>
</el-table-column> -->
        <!-- <el-table-column prop="sort" label="排序" min-width="50" /> -->
        <!-- <el-table-column prop="status" label="是否显示" min-width="150">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isShow"
              :active-value="1"
              :inactive-value="0"
              active-text="显示"
              inactive-text="隐藏"
              @change="onchangeIsShow(scope.row)"
            />
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="140" fixed="right">
          <template slot-scope="scope">
            <el-button icon="el-icon-edit" type="text" size="small" @click="onEdit(scope.row)">编辑</el-button>
            <el-button icon="el-icon-delete" type="text" size="small" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination background :page-size="tableFrom.pageSize" :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper" :total="tableData.total" @size-change="handleSizeChange"
          @current-change="pageChange" />
      </div>
    </el-card>

    <el-dialog :title="title" :visible.sync="dialogVisible" width="500" :before-close="handleClose">
      <avue-form :option="option" v-model="form" ref="form" :upload-error="uploadError" :upload-delete="uploadDelete"
        :upload-before="uploadBefore" :upload-after="uploadAfter" @submit="formSubmit">
        <!-- <template slot="image">
          <FormImgUpload :url="form.image" @upload="upload" :limit="1" :maxSize="5" accept="image/jpeg,image/png,image/jpg"
            :multiple="true" />
        </template> -->
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
          <el-button type="primary" icon="el-icon-check" @click="submit(form)">确定</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <Import
      v-if="importShow"
      :show="importShow"
      :action="importAction"
      :downloadLink="importDownloadLink"
      :problemLink="importProblem"
      @close="importClose"
    />
  </div>
</template>

<script>
import {
  goodsCategoryAdd,
  goodsCategoryList,
  goodsCategoryDel,
  goodsCategoryState,
  goodsCategoryUpdate,
} from "@/api/goods/goods";
import {
  storeDeptList,
  deptAdd,
  deptDelete,
  deptUpdate,
  deptTree
} from "@/api/store/department";
import Import from "./comments/import.vue";
import FormImgUpload from "@/components/formComponents/formImgUpload";
export default {
  name: "ProductClassify",
  components: {
    FormImgUpload,
    Import
  },
  data() {
    return {
      importShow: false,
      importAction: "",
      importDownloadLink: "",
      importProblem: "",
      moren: require("@/assets/images/bjt.png"),
      isChecked: false,
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 10
      },
      title: "新增部门",
      dialogVisible: false,
      form: {
        image: [],
        isShow: 0,
        type: 1,
      },
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          // {
          //   label: "上级部门",
          //   prop: "parentId",
          //   type: "cascader",
          //   maxlength: 20,
          //   row: true,
          //   placeholder: "请输入",
          //   checkStrictly: true,
          //   props: { checkStrictly: true, label: "name", value: "id" },
          //   dicData: [],
          //   // rules: [
          //   //   {
          //   //     required: true,
          //   //     message: "请选择",
          //   //     trigger: "change",
          //   //   },
          //   // ],
          // },
          {
            label: "部门名称",
            prop: "name",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入部门名称",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "分类图标",
          //   row: true,
          //   type: "text",
          //   prop: "image",
          //   span: 24,
          //   rules: [
          //     {
          //       required: true,
          //       message: "请上传分类图片",
          //       trigger: "blur",
          //     },
          //     {
          //       validator: (rule, value, callback) => {
          //         if (value.length === 0) {
          //           callback(new Error("请上传"));
          //         } else {
          //           callback();
          //         }
          //       },
          //     },
          //   ],
          // },
          // {
          //   label: "是否显示",
          //   prop: "isShow",
          //   type: "switch",
          //   row: true,
          //   dicData: [
          //     {
          //       label: "关",
          //       value: 0,
          //     },
          //     {
          //       label: "开",
          //       value: 1,
          //     },
          //   ],
          // },
          // {
          //   label: "排序",
          //   prop: "sort",
          //   controlsPosition: "",
          //   type: "number",
          // },
        ],
      },
    };
  },
  mounted() {
    this.getList();
    this.getdeptTree()
  },
  methods: {
    importFun(){
      this.importAction = "/api/user/web/store/dept/template/import";
      this.importDownloadLink = "/api/user/web/store/dept/export";
      this.importProblem = "/api/user/web/store/deft/template/error/export";
      this.importShow = true;
    },
    // 导入弹框关闭
    importClose(e) {
      this.importShow = false;
      if (e === 1 || e === 2) {
        this.getList();
      }
    },
    // 获取部门树分类
    getdeptTree(){
      deptTree().then(({code,data})=>{
        if(code==200){
          this.option.column[0].dicData=data
        }
      })
    },
    uploadDelete() {
      // return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column);
    },
    uploadAfter(res, done) {
      done();
      // this.$message.success('上传后的方法')'
      // console.log(res);
      // this.form.image = res.image;
      // this.$nextTick(() => {
      //   this.form.image = res.path;
      // });
    },
    formSubmit() { },
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          console.log(form);
          let that = this;
          // let parentId=form.parentId[form.parentId.length-1]||0
          // return
          if (this.form.id) {
            deptUpdate({
              id: form.id,
              // parentId,
              name: form.name
            }).then(res => {
              this.$message.success("修改成功");
              this.handleClose();
              this.getList();
              this.getdeptTree()
            })
          } else {
            deptAdd({
              // parentId,
              name: form.name
            }).then(res => {
              this.$message.success("添加成功");
              this.getdeptTree()
              this.handleClose();
              this.getList();
            })
          }
          // if (this.form.id) {
          //   if (that.form.parentId.length == 3) {
          //     that.$message.error("最多新增3级");
          //     return;
          //   }
          //   goodsCategoryUpdate({
          //     ...that.form,
          //     icon: that.form.image.length > 0 ? that.form.image[0] : "",
          //     parentId: that.form.parentId[0],
          //   }).then((res) => {
          //     // 获取数据成功后的其他操作
          //     // console.log(res);
          //     if (res.code === 200) {
          //       this.$message.success("修改成功");
          //       that.getList();
          //       that.handleClose();
          //       // done();
          //     } else {
          //       that.$message.error(res.msg);
          //       // done();
          //     }
          //   });
          // } else {
          //   if (that.form.parentId.length == 3) {
          //     that.$message.error("最多新增3级");
          //     return;
          //   }
          //   goodsCategoryAdd({
          //     ...that.form,
          //     icon: that.form.image.length > 0 ? that.form.image[0] : "",
          //     parentId: that.form.parentId[that.form.parentId.length - 1],
          //   }).then((res) => {
          //     // 获取数据成功后的其他操作
          //     // console.log(res);
          //     if (res.code === 200) {
          //       that.$message.success(res.msg);
          //       that.getList();
          //       that.handleClose();
          //       // done();
          //     } else {
          //       that.$message.error(res.msg);
          //       // done();
          //     }
          //   });
          // }
          // this.form.type = 1;
          // done()
        }
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.image = [];
      this.form.isShow = 0;
      this.dialogVisible = false;
    },
    // 列表
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    getList() {
      this.listLoading = true;
      console.log(this.tableFrom, 'this.tableFromthis.tableFrom');
      storeDeptList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          // let arr = JSON.parse(JSON.stringify(res.data));
          // arr.unshift({
          //   name: "顶级分类",
          //   id: "0",
          // });
          // this.option.column[0].dicData = arr;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
          this.$message.error(res.message);
        });
    },
    // 添加
    onAdd() {
      this.title='添加部门'
      this.form.id = '';
      this.dialogVisible = true;
    },
    // 编辑
    onEdit(row) {
      this.title='编辑部门'
      this.form = JSON.parse(JSON.stringify(row));
      console.log("1231", this.form);
      this.form.image = [this.form.icon];
      this.form.parentId = [this.form.parentId];
      console.log("1231", this.form);
      this.dialogVisible = true;
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
    // 删除
    handleDelete(id) {
      console.log(id, 'idid');
      // return
      let that = this;
      this.$confirm(`删除这条数据`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            console.log(id, 'idid');
            deptDelete(id)
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code === 200) {
                  that.getList();
                  that.$message.success(res.msg);
                } else {
                }
              })
              .catch((err) => {
              });
          }
        },
      });
    },
    onchangeIsShow(row) {
      goodsCategoryState({ id: row.id, isShow: row.isShow })
        .then(({ msg }) => {
          this.getList();
          this.$message.success(msg);
        })
        .catch(({ msg }) => {
          this.$message.error(msg);
          this.getList();
        });
    },
  },
};
</script>

<style scoped lang="scss">
.mb10 {
  margin-bottom: 10px;
}
</style>
